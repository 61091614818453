<template>
  <nav class="navigation flex-container flex-direction-row">
    <div class="main-links flex-item col-12 flex-container flex-top centered">
      <router-link class="item flex-item mar-l-3 mar-r-3 flex-container" to="/"> Home  </router-link>
      <router-link class="item flex-item mar-l-3 mar-r-3 flex-container" to="/career"> Career  </router-link>
      <router-link class="item flex-item mar-l-3 mar-r-3 flex-container" to="/projects"> Projects  </router-link>

      <a v-if="$store.state.userToken.signedin == true" class="item button flex-item mar-l-6  mar-r-0 pad-l-4 pad-r-4 pad-t-2 pad-b-2 flex-container" download target="_blank" href="https://ik.imagekit.io/ymem6x6ud5/pixelswish-v3/Richard_Silcock_CV.pdf?updatedAt=1725906020760"> Download CV  </a>
    </div>

  </nav>
</template>

 <script>

  export default {
    data() {
      return {
        staffToggle: false,
        officeToggle: false,
        expandNav: true
      }
    },
    components: {
    },
    mounted: function() {
    },
    methods: {
      expandNavEvent(){
        this.expandNav = !this.expandNav
        this.officeToggle = false
        this.staffToggle = false
      }
    }
  }

</script>

<style lang="scss" scoped>

.item {
  text-decoration: none;
  color: var(--link-color);

  &:hover {
    color: var(--primary-color) !important;
  }
  &.router-link-exact-active {
    color: var(--primary-color);
    font-weight: 800;
  }

  &.button {
    color: var(--primary-color);
    text-decoration: none;
    background-color: transparent;
    border-radius: 10px;
    border: solid 2px var(--primary-color);
    align-items: center;

    &:hover {
      color: var(--black-90) !important;
      background: var(--primary-color) !important;
    }
  }

}

</style>
