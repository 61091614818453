import { createRouter, createWebHistory } from "vue-router";
import store from '../store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Index-View",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Index-View.vue"),
        meta: {
          title: 'Home',
          requiresAuth: false,
          breadcrumb: [
            { name: 'Home' },
          ]
        },
    },
    {
      path: "/projects",
      name: "Projects",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Projects-View.vue"),
        meta: {
          title: 'Projects',
          requiresAuth: false,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects' }
          ]
        },
    },
    {
      path: "/career",
      name: "Career",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
      import(/* webpackChunkName: "about" */ "../views/Career-View.vue"),
        meta: {
          title: 'Career',
          requiresAuth: false,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'About' }
          ]
        },
    },
    {
      path: "/projects/strategic-design-leadership",
      name: "Strategic Design Leadership",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/strategic-design-leadership-View.vue"),
        meta: {
          title: 'Strategic Design Leadership',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Strategic Design Leadership' }
          ]
        },
    },
    {
      path: "/projects/self-service-betting-terminal",
      name: "Self Service Betting Terminal",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/self-service-betting-terminal-View.vue"),
        meta: {
          title: 'Self Service Betting Terminal',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Self Service Betting Terminal' }
          ]
        },
    },
    {
      path: "/projects/creating-accounts",
      name: "Regatta - creating-accounts",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/creating-accounts.vue"),
        meta: {
          title: 'Regatta - creating-accounts',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Regatta - creating-accounts' }
          ]
        },
    },
    {
      path: "/projects/future",
      name: "Future App",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/future-app.vue"),
        meta: {
          title: 'Future App',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Future App' }
          ]
        },
    },
    {
      path: "/projects/accessibility-in-mind",
      name: "Accessibility In Mind",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/accessibility-in-mind.vue"),
        meta: {
          title: 'Accessibility In Mind',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Accessibility In Mind' }
          ]
        },
    },
    {
      path: "/projects/regatta-group-checkout",
      name: "Regatta Group Checkout",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/regatta-group-checkout.vue"),
        meta: {
          title: 'Regatta Group Checkout',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Regatta Group Checkout' }
          ]
        },
    },
    {
      path: "/projects/zendesk-contact-form",
      name: "Zendesk Contact Form",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/projects/zendesk-contact-form.vue"),
        meta: {
          title: 'Zendesk Contact Form',
          requiresAuth: true,
          breadcrumb: [
            { name: 'Home', link: '/' },
            { name: 'Projects', link: '/' },
            { name: 'Zendesk Contact Form' }
          ]
        },
    },



  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = store.state.userToken.signedin;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const DEFAULT_TITLE = 'Pixel Swish - ';
  document.title =  DEFAULT_TITLE + to.meta.title;


  if (requiresAuth && !currentUser) next('/')
  // else if (!requiresAuth && currentUser) next('/');
  else next();
});

export default router;
