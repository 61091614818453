import { createStore } from 'vuex'

export default createStore({
  state: {
    userToken: {
      signedin: false,
      access: 'See2024',
      // Hello you magnificent code explorer, the above is stored in plain text to only prevent random people seeing and stealing my work. (Yes, it's happened) Not as a proper security measure.
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
