import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import "./assets/css/root.scss";


var firebaseConfig = {
  apiKey: "AIzaSyBB4fDCfgMVjWQo7aQDl9zAPNgUT5KqMYM",
  authDomain: "pixelswish-dfc81.firebaseapp.com",
  projectId: "pixelswish-dfc81",
  storageBucket: "pixelswish-dfc81.appspot.com",
  messagingSenderId: "522984580847",
  appId: "1:522984580847:web:1dcc402d6505f4d40d1d1a",
  measurementId: "G-WM9NGLFKZR"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const projectAuth = firebase.auth();

export { projectAuth };
firebaseApp.analytics();

if(localStorage.getItem('access') == 'true') {
  store.state.userToken.signedin = true
} else {
  store.state.userToken.signedin = false
}

createApp(App).use(store).use(router).mount("#app");
 

