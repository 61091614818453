<template>
  <header class="flex-container flex-item col-12 flex-center" id="nav" :class="{ transparent: $route.path != '/' &&  $route.path != '/career' &&  $route.path != '/projects'   }">

    <div class="wapper flex-container flex-item col-12 pad-2 pad-lg-l-6 pad-lg-r-6  flex-space container-xl">
      <div class="logo-row flex-container centered">
        <button v-if="window.width <= 1024" @click.prevent="expandNav = !expandNav" class="flex-container centered mar-r-4" to="">
          <span v-if="!expandNav" class="material-symbols-rounded mr-2"> menu </span>
          <span v-if="expandNav" class="material-symbols-rounded mr-2"> close </span>
        </button>
        <h3 class="logo mar-0">Pixel Swish <span></span></h3>
      </div>
      <mainNavigation :class="{ active: window.width > 1024 || window.width <= 1024 && expandNav }" />
    </div>
  </header>

  <main class="flex-item flex-container flex-center align-con-top">
    <router-view />
  </main>

</template>

<script>
import mainNavigation from "@/components/navigation/main-navigation.vue";


export default {
  name: "App",
  data() {
    return {
      expandNav: false,
      window: {
        width: 0,
      }
    };
  },
  components: {
    mainNavigation,
  },
  watch:{
    $route() {
      this.expandNav = false
      document.documentElement.scrollTop = 0;
    },
  },
  routeName(){
     return this.$route.name
  },
  mounted:function(){
    window.addEventListener("scroll", function() {
      var elementTarget = document.querySelector("#nav");
      if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
        elementTarget.classList.add('active')
      } else {
        elementTarget.classList.remove('active')
      }
    });
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
  }
};
</script>


<style lang="scss" scoped>
#app {
  height: 100%;

  header {
    position: fixed;
    width: 100%;
    height: 120px;
    top:0;
    left: 0;
    z-index: 5;
    backdrop-filter: blur(12px);
    background-color: rgba(4, 4, 28, 0.8);
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    opacity: 1;
    transition:all ease-in-out 0.5s;


    &.transparent {
      background: rgba(4, 4, 28, 0.3);
      // backdrop-filter: blur(32px);
    }

    @media only screen and (min-width: 1024px) {
      &.active {
        height: 80px;
        background: rgba(4, 4, 28, 0.8);

      }
    }

    @media only screen and (max-width: 1024px) {
      .logo-row {
        width: 100%;
      }
    }
    button {
      color: var(--black-0);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background: transparent
    }

    .logo {
      @media only screen and (max-width: 1024px) {
        text-align: center;
        font-size: 22px;
        width: 100%;
        max-width: calc(100% + -57px);
        padding-right: 46px;
      }


      span {
        width: 5px;
        height: 5px;
        background-color: rgb(253 218 95);
        display: inline-block;
      }
    }
    @media only screen and (max-width: 1024px) {
      height: 70px;
    }
  }

  nav {
    @media only screen and (max-width: 1024px) {
      position: fixed;
      width: 100%;
      left: -100%;
      top: 70px;
      z-index: 9;
      min-height: calc(100% - 65px);
      height: calc(100% - 65px);
      background: var(--bg-color);

      &:deep(.main-links) {
        flex-direction: column;
        align-self: flex-start;
        z-index: 3;
        background: #03031d;
        height: calc(100vh - 70px);

        a {
          margin: 20px !important;
          padding: 20px !important;
          max-height: 70px;
          &.button {
            padding: 20px 60px !important;
          }
        }
      }

      &.active {
        left: 0%;
        -webkit-transition: all ease-in-out 0.2s;
        transition: all ease-in-out 0.2s;
      }

    }

  }

  main {
    width: 100%;
    margin-top: 0;
    background: var(--background);
    padding-top: 120px;

    @media only screen and (max-width: 1024px) {
      padding-top: 70px;

    }

  }

}
</style>
